<template>
    <div class="systemCommonTemplate_container">
        <div class="systemCommonTemplate_title global_text_content">
            <el-input
                ref="title"
                v-model="data.title"
                @input="changeTitle"
                placeholder="点此输入标题"
                type="textarea"
                @blur="showProxy =  true"
            />
            <div class="global_text_count">{{getCount('title')}}</div>
            <div class="systemCommonTemplate_title_proxy" @click="focusTitle" v-if="showProxy">
                <div>
                    <span>{{data.title}}</span>
                    <span class="systemCommonTemplate_title_msg">{{getInputMsg()}}</span>
                </div>
            </div>
        </div>
        <div class="systemCommonTemplate_content global_text_content">
            <el-input
                v-model="data.content"
                @input="changeContent"
                placeholder="点此输入正文"
                type="textarea"
            />
            <div class="global_text_count global_text_count_content">{{getCount('content')}}</div>
        </div>
        <div class="systemCommonTemplate_images ">
            <DisplayImages :isEdit="true" @addFile="addFile" @removeFile="removeFile" :images="data.images" :meetId="meetId" type="病例" />
        </div>
    </div>
</template>
<script>
import { ElInput } from 'element-plus'
import CustomImage from '@/components/unit/customImage'
import DisplayImages from '@/components/unit/displayImages'
import {ElIcon} from 'element-plus';
import { Plus,Close } from '@element-plus/icons'
export default {
    name:"systemCommonTemplate",
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        meetId:Number
    },
    components:{
        ElInput,
        CustomImage,
        DisplayImages,
        ElIcon,
        Close,
        Plus
    },
    data(){
        return {
            data:{
                title:"",
                content:"",
                images:[]
            },
            showProxy:true,
            inputMsg:['','如免疫、靶向、化疗等点击可修改标题',''],
            keys:['title','content','images']
        }
    },
    created(){
        this.initData()
    },
    methods:{
      initData(dataKey,item){
            dataKey = dataKey || "class_content"
            this.keys.forEach((key,index)=>{
                if(key == 'images'){
                    this.data[key] = item ?? this.pageItem.content[index][dataKey].split(',').filter(x=>x!="")
                }else{
                    this.data[key] = item ??  this.pageItem.content[index][dataKey]
                }
            })
        },
        clearInfo(){
            this.initData('default_content','')
        },
        restore(){
            this.initData('default_content')
        },
        getInputMsg(){
            let content = this.inputMsg[this.pageItem.systemIndex-1];
            console.log(this.pageItem)
            return content ? `(${content})` : "(点击可修改标题)"
        },
        getCount(key){
            let data = this.$tools.dealWithText(this.data[key],this.getCfg(key,'template_max'))
            return `${data.count}/${this.getCfg(key,'template_max')}`
        },
        changeTitle(){
            this.data.title = this.$tools.dealWithText(this.data.title,this.getCfg('title','template_max')).content
        },
        changeContent(){
            this.data.content = this.$tools.dealWithText(this.data.content,this.getCfg('content','template_max')).content
        },
        
        getCfg(key,attr){
            let index = this.keys.indexOf(key)
            return this.pageItem.content[index][attr]
        },
    
        getCfg(key,attr){
            let index = this.keys.indexOf(key)
            return this.pageItem.content[index][attr]
        },
        focusTitle(){
            this.showProxy = false
            console.log( this.$refs.title)
            this.$refs.title.focus()
        },
        formatData(){
            let data = []
            let code = 200
            for(let index=0;index<this.keys.length;index++){
                let key = this.keys[index]
                if(key == "images"){
                    data.push(this.data[key].join(','))
                    continue;
                }
                data.push(this.data[key])
            }
            if(this.data.title == ""){
                this.$tips.error({text:"请填写标题"})
                code = 400

            }
            if(code != 400 && this.data.content == "" && this.data.images.length == 0){
                this.$tips.error({text:"内容和图片请至少填写一项"})
                code =  400
            }
            data = data.map((item,index)=>{
                return {
                    class_content:item,
                    update_status:1,
                    id:this.pageItem.content[index].id
                }
            })
            return {code,data}
        },
        doEdit(){},
        addFile(urls){
            this.data.images = this.data.images.concat(urls)
        },
        removeFile(index){
            this.data.images.splice(index,1)
        },
        getData(callback){
            let data = this.formatData()
            callback(data)
        }
    }
}
</script>
<style>
.systemCommonTemplate_container{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
}
.systemCommonTemplate_title{ 
    background: linear-gradient(#9e004b,#a0044e,#cc6f9c);
    height:16.4vw;
    position: relative;
}
.systemCommonTemplate_title textarea{
    background:transparent;
    border:none;
    color:#fff;
}
.systemCommonTemplate_title textarea::placeholder{
    color:#fff;
}
.systemCommonTemplate_title  .el-input__count{
    background:transparent;
    color:#fff;
}

.systemCommonTemplate_content textarea::placeholder{
    color:red;
}
.systemCommonTemplate_content textarea{
    height:22vw;
}
.systemCommonTemplate_images{
    flex:1 0 0;
    overflow: hidden;
}
.systemCommonTemplate_content{
    border-bottom:1px solid #e6e6e6;
}
.systemCommonTemplate_title_proxy{
    background: linear-gradient(#9e004b,#a0044e,#cc6f9c);
    display: flex;
    align-items: center;
    width:100%;
    height:100%;    
    position: absolute;
    left:0;
    top:0;
    color:#fff;
    padding:0 4vw;
    z-index:2;
    
}
.systemCommonTemplate_title_msg{
    font-size:2.8vw;
}
</style>